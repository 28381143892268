















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PaginationButton extends Vue {
  @Prop({default: false, type: Boolean})
  disabled!: boolean;

  @Prop({default: false, type: Boolean})
  active!: boolean;

  @Prop()
  title?: string;
}
