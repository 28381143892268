















import { Component, Prop, Vue } from 'vue-property-decorator'
import Card from '@/components/Card/Card.vue'

@Component({
  components: {
    Card
  }
})
export default class CardWithTitle extends Vue {
  @Prop()
  title!: string;

  @Prop({ default: false, type: Boolean })
  noPadding !: boolean;

  @Prop({ default: false, type: Boolean })
  noCard !: boolean;
}
